<template>
  <div
    class="rounded-md p-4"
    :class="config.bgColor"
  >
    <div class="flex items-center">
      <div class="flex-shrink-0">
        <Component
          :is="config.icon"
          class="h-5 w-5"
          :class="config.textColor"
        />
      </div>
      <div
        class="ml-3 text-xs"
        :class="config.textColor"
        v-bind="$attrs"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  CheckCircleIcon, InformationCircleIcon, XCircleIcon, ExclamationCircleIcon,
} from '@heroicons/vue/24/solid';
import { computed } from 'vue';

type AlertType = 'info' | 'success' | 'warning' | 'error';

const props = defineProps<{ type: AlertType }>();

const iconMatch = (value: AlertType) => new Map([
  ['info', InformationCircleIcon],
  ['success', CheckCircleIcon],
  ['warning', ExclamationCircleIcon],
  ['error', XCircleIcon],
]).get(value);

const bgColorMatch = (value: AlertType) => new Map([
  ['info', 'bg-blue-50'],
  ['success', 'bg-green-50'],
  ['warning', 'bg-yellow-50'],
  ['error', 'bg-red-50'],
]).get(value);

const textColorMatch = (value: AlertType) => new Map([
  ['info', 'text-blue-800'],
  ['success', 'text-green-800'],
  ['warning', 'text-yellow-800'],
  ['error', 'text-red-800'],
]).get(value);

const config = computed(() => ({
  icon: iconMatch(props.type),
  bgColor: bgColorMatch(props.type),
  textColor: textColorMatch(props.type),
}));
</script>
